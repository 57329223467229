<template>
  <div>
    <div class="img">
      <swiper :options="swiperOptionGK" class="swiperList">
<!--        <swiper-slide class="item-swiper">-->
<!--          <video src="https://zhongtai-media-test-1309102353.cos.ap-shanghai.myqcloud.com/huyunhe/1653964374000.png"></video>-->
<!--          <video width="100%" height="100%" controls>-->
<!--            <source src="https://zhongtai-media-test-1309102353.cos.ap-shanghai.myqcloud.com/huyunhe/1653964374000.png"  type="video/mp4">-->
<!--          </video>-->
<!--          <d-player ref="player" id="player" :options="dplayerOptions2"></d-player>-->
<!--        </swiper-slide>-->
        <swiper-slide v-for="(item,index) in dataInfo.imgList" class="item-swiper">
          <div v-if="typeof(item) == 'object'" style="width: 100%;height: 100%;">
<!--            <video :src="item.video"></video>-->
            <video width="100%" height="100%" controls>
              <source :src="item.video"  type="video/mp4">
            </video>
          </div>
          <img v-else :src="item" width="100%" height="100%" style="object-fit: cover;" alt="">
        </swiper-slide>
      </swiper>
      <div class="img-icon">
        <img src="../../assets/img/shubiao.png" width="100%" height="100%" alt="">
      </div>
    </div>
    <div class="box" v-html="dataInfo.content">
<!--      <div class="box-1">
        江南古运河在无锡清名桥处与世界上最古老的运河伯渎港交汇，是1794公里京杭大运河保存风貌最完整、保存历史遗存最多、最具有江南水乡风情，并且唯一穿城而过的一段古运河。至今仍延续着通航、蓄水、防洪、排涝、生态、景观、游憩等功能。
        江南古运河旅游度假区沿线有国保单位6处，省保单位24处，环城古运河“绕城而过，独此一环”，还有清名桥、惠山两大历史文化街区以及黄埠墩、米市、丝市、名人故居等旅游和历史文化资源，承载了运河文化深厚底蕴。特别是从南长桥至清名桥1.6公里长的古运河段，寺、塔、河、街、桥、窑、宅、坊众多空间元素有机组合，成为一幅鲜活的“清明上河图”，被誉为“江南水弄堂、运河绝版地”。度假区目前拥有2个世界文化遗产点段、6个国家4A级景区和1个中国历史文化名街。
        近年来，江南古运河旅游度假区深入挖掘运河文化、民俗文化、民族工商业文化等历史文化旅游资源，结合古运河、惠山古镇、南禅寺、清名桥、黄埠墩等“一河两岸”历史文化建筑群、古遗址、名人故居等文化遗产和生态公园，开发了“枕河人家”旅游度假产品，开辟了多条精品旅游线路，形成了以运河世界文化遗产为核心，运河文化休闲旅游为特色，融旅游、文化、商业、运动、健康于一体的文化休闲旅游度假区。其中，清名桥历史文化街区先后获得国家4A级景区、中国历史文化名街、中国著名商业街、“龙腾奖”中国创新意产最佳园区奖等多项国家级荣誉。惠山古镇号称无锡历史文化的露天博物馆，已被纳入世界文化遗产预备录名单，其古祠堂群为全国重点保护文物，华孝子祠等10座祠堂为全国文物保护重点祠堂建筑。惠山古镇所在的锡惠风景区为国家4A级景区，正在努力打造世界文化遗产、国家5A级景区和无锡非物质文化旅游体验区。
      </div>
      <img src="@/assets/img/s-yi.png" class="img" alt="">
      <div class="box-1">
        无锡古运河是京杭大运河的一段，它北接长江，南达太湖，全长四十多公里，纵贯无锡城区。无锡古运河的历史可以追溯到商末，三千年前，周太王长子泰伯在梅里建勾吴国，为了灌溉和排洪的需要，就率领民众开凿了伯渎河。历史上吴王阖闾攻楚，夫差北上伐齐，都曾通过这条河。可见这条河当时不仅是在交通和农业灌溉上发挥着重要作用，而且在军事上也起着重要的作用。这条河不仅是无锡最早的人工河，应该说也是人类历史上最早的运河了。如今无锡人叫它伯渎河，也叫伯渎港，经梅村可到常熟。如今，这条河虽然已经失去了它往日的重要作用，但悠悠河水，凝聚了无锡三千年风风雨雨的历史，交织着水乡河古朴醇厚的民风民俗。
      </div>
      <img src="@/assets/img/s-er.png" class="img" alt="">
      <div class="box-1">
        江南古运河在无锡清名桥处与世界上最古老的运河伯渎港交汇，是1794公里京杭大运河保存风貌最完整、保存历史遗存最多、最具有江南水乡风情，并且唯一穿城而过的一段古运河。至今仍延续着通航、蓄水、防洪、排涝、生态、景观、游憩等功能。
        江南古运河旅游度假区沿线有国保单位6处，省保单位24处，环城古运河“绕城而过，独此一环”，还有清名桥、惠山两大历史文化街区以及黄埠墩、米市、丝市、名人故居等旅游和历史文化资源，承载了运河文化深厚底蕴。特别是从南长桥至清名桥1.6公里长的古运河段，寺、塔、河、街、桥、窑、宅、坊众多空间元素有机组合，成为一幅鲜活的“清明上河图”，被誉为“江南水弄堂、运河绝版地”。度假区目前拥有2个世界文化遗产点段、6个国家4A级景区和1个中国历史文化名街。
        近年来，江南古运河旅游度假区深入挖掘运河文化、民俗文化、民族工商业文化等历史文化旅游资源，结合古运河、惠山古镇、南禅寺、清名桥、黄埠墩等“一河两岸”历史文化建筑群、古遗址、名人故居等文化遗产和生态公园，开发了“枕河人家”旅游度假产品，开辟了多条精品旅游线路，形成了以运河世界文化遗产为核心，运河文化休闲旅游为特色，融旅游、文化、商业、运动、健康于一体的文化休闲旅游度假区。其中，清名桥历史文化街区先后获得国家4A级景区、中国历史文化名街、中国著名商业街、“龙腾奖”中国创新意产最佳园区奖等多项国家级荣誉。惠山古镇号称无锡历史文化的露天博物馆，已被纳入世界文化遗产预备录名单，其古祠堂群为全国重点保护文物，华孝子祠等10座祠堂为全国文物保护重点祠堂建筑。惠山古镇所在的锡惠风景区为国家4A级景区，正在努力打造世界文化遗产、国家5A级景区和无锡非物质文化旅游体验区。
      </div>
      <img src="@/assets/img/s-san.png" class="img" alt="">-->
    </div>
  </div>
</template>

<script>

import {getBannerowInfo} from '@/api/table'
export default {
  name: "gaikuang",
  data(){
    return {
      swiperOptionGK: {
        navigation: {
          nextEl: '.scenicRight',
          prevEl: '.scenicLeft',
        },
        direction: "horizontal", // 竖向滚动
        // autoHeight: true, // 自适应高度
        slidesPerView: "auto", // 记得这里写 auto 不要默认写1哦
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,

      },
      dataInfo:{}
    }
  },
  mounted() {
    this.createList()
  },
  methods:{
    createList(){
      getBannerowInfo({
        bannerType:'1',   // 必填 banner类型：1度假区概况 2度假区总览图 3交通指引 4官网首页轮播图 5交通指引（公交、高铁、飞机）6首页度假区概况
        // sightseeingOnline:'1',   // 是否上线 0否 1是
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          this.dataInfo=res.rows[0]
          this.dataInfo.imgList = res.rows[0].image.split(',')
          if(this.dataInfo.videoPath){
            this.dataInfo.imgList.unshift({video:this.dataInfo.videoPath})
          }
          // this.totalCount=res.total
        }
      })
    },
    select(){

    },
    changep(){

    }
  }
}
</script>

<style scoped lang="less">
.swiperList{
  height: 100%;
  width: 100%;
  /deep/.swiper-wrapper{
    display: flex;
  }
  .item-swiper{
    height: 800px;
    overflow: hidden;
    min-width: 100%;
  }
}
.img{
  width: 100%;
  height: 800px;
  position: relative;
  .img-icon{
    width: 108px;
    height: 72px;
    position: absolute;
    left: 50%;
    margin-left: -54px;
    bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% 100% 0 0;
  }
}
.box{
  width: 1300px;
  height: 100%;
  background: #FFFFFF;
  margin: 50px auto;
  padding: 50px;
  box-sizing: border-box;
  //font-size: 14px;
  font-family: STSongti-SC-Bold, STSongti-SC;
  //font-weight: bold;
  color: #333333;
  line-height: 30px;
  .box-1{
    padding: 50px 0;
    text-indent:2em;
    font-size: 14px;
    line-height: 30px;
  }
  .img{
    width: 1200px;
    height: 640px;
  }
}
</style>
