<template>
  <div>
    <div class="img">
      <img src="@/assets/img/jiaotongtu.png" width="100%" height="100%" alt="">
      <div class="jianbian" v-if="tType == 'CH'">如何到达</div>
      <div class="jianbian" v-if="tType == 'EN'">how to reach</div>
<!--      <div class="img-icon">-->
<!--        <img src="@/assets/img/shubiao.png" width="100%" height="100%" alt="">-->
<!--      </div>-->
    </div>
    <div class="box" v-if="tType == 'CH'">
      <div class="box-1" v-for="item of list" :key="item.id" @click="itembtn(item.id)">
        <img :src="item.xian" width="100%" height="100%" alt="" :class="{'img1':true,'isActive':item.id==id}">
        <img :src="item.yin" width="100%" height="100%" alt="" :class="{'img2':true,'isNoActive':item.id==id}" >
      </div>
    </div>
    <div class="box" v-if="tType == 'EN'">
      <div class="box-1" v-for="item of list2" :key="item.id" @click="itembtn(item.id)">
        <img :src="item.xian" width="100%" height="100%" alt="" :class="{'img1':true,'isActive':item.id==id}">
        <img :src="item.yin" width="100%" height="100%" alt="" :class="{'img2':true,'isNoActive':item.id==id}" >
      </div>
    </div>
    <div class="baixian"></div>
    <div class="item-1">
      <zijia v-show="id===1" :info="ZJInfo"></zijia>
      <gongjiao v-show="id===2" :info="GJInfo"></gongjiao>
      <gaotie v-show="id===3" :info="TLInfo"></gaotie>
      <feiji v-show="id===4" :info="FJInfo"></feiji>
    </div>
  </div>
</template>

<script>
import {getBannerowInfo} from "@/api/table";

export default {
  name: "lantu",
  components:{
    Zijia:()=>import('@/views/vacation/item/zijia'),
    gongjiao:()=>import('@/views/vacation/item/gongjiao'),
    gaotie:()=>import('@/views/vacation/item/gaotie'),
    feiji:()=>import('@/views/vacation/item/feiji'),
  },
  data(){
    return {
      tType:'CH',
      list:[
        {
          id:1,
          xian:require('@/assets/img/x-zijia.png'),
          yin:require('@/assets/img/zijia.png'),
        },
        {
          id:2,
          xian:require('@/assets/img/x-gongjiao.png'),
          yin:require('@/assets/img/gongjiao.png'),
        },
        {
          id:3,
          xian:require('@/assets/img/x-gaotie.png'),
          yin:require('@/assets/img/gaotie.png'),
        },
        {
          id:4,
          xian:require('@/assets/img/x-feiji.png'),
          yin:require('@/assets/img/feiji.png'),
        },
      ],
      list2:[
        {
          id:1,
          xian:require('@/assets/img/x-zijia2.png'),
          yin:require('@/assets/img/zijia2.png'),
        },
        {
          id:2,
          xian:require('@/assets/img/x-gongjiao2.png'),
          yin:require('@/assets/img/gongjiao2.png'),
        },
        {
          id:3,
          xian:require('@/assets/img/x-gaotie2.png'),
          yin:require('@/assets/img/gaotie2.png'),
        },
        {
          id:4,
          xian:require('@/assets/img/x-feiji2.png'),
          yin:require('@/assets/img/feiji2.png'),
        },
      ],
      id:1,
      ZJInfo:{},
      GJInfo:{},
      TLInfo:{},
      FJInfo:{},
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    // this.createList()
  },
  methods:{
    createList(){
      getBannerowInfo({
        bannerType:'5',   // 必填 banner类型：1度假区概况 2度假区总览图 3交通指引 4官网首页轮播图 5交通指引（公交、高铁、飞机）6首页度假区概况
        // sightseeingOnline:'1',   // 是否上线 0否 1是
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          res.rows.forEach((item,index)=>{
            if(item.content=='自驾'){this.ZJInfo = item}
            if(item.content=='公交'){this.GJInfo = item}
            if(item.content=='铁路'){this.TLInfo = item}
            if(item.content=='飞机'){this.FJInfo = item}
          })
          // this.totalCount=res.total
        }
      })
    },
    itembtn(id){
      this.id=id
    },
  }
}
</script>

<style scoped lang="less">
/deep/.jianbian{
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,123,138,.5);
  color: #FFFFFF;
  font-size: 40px;
  text-align: center;
  line-height: 300px;
  font-family: SimSun;
  font-weight: 700;
}
.baixian{
  width: 1300px;
  height: 180px;
  background: #FFFFFF;
  position: absolute;
  top: 300px;
  left: 50%;
  margin-left: -650px;
  border-radius: 40px 40px 0 0;
}
.img{
  width: 100%;
  height: 400px;
  position: relative;
  //background: rgba(0,123,138,.5) !important;
  .img-icon{
    width: 108px;
    height: 72px;
    position: absolute;
    left: 50%;
    margin-left: -54px;
    bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% 100% 0 0;
  }
}
.box{
  width: 1300px;
  height: 100%;
  //border-radius: 40px 40px 0 0;
  //background: #FFFFFF;
  margin: 60px auto;
  display: flex;
  justify-content: space-around;
  margin-top: -60px;

  position: relative;
  .box-1{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    //background: red;
    cursor: pointer;
    margin-top: -100px;
    position: relative;
    z-index: 99;
    margin-bottom: 60px;
    .img1{
      display: none;
    }
    .img2{
      display: block;
    }
    .isActive{
      display: block;
    }
    .isNoActive{
      display: none;
    }
    &:hover{
      .img1{
        display: block;
      }
      .img2{
        display: none;
      }
    }
  }
}
.item-1{
  width: 1300px;
  margin: 0 auto;
}
</style>
